import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"

const INPUT_TYPES = [
  "text",
  "password",
  "submit",
  "reset",
  "radio",
  "checkbox",
  "button",
  "file",
  "image",
  "color",
  "date",
  "datetime-local",
  "email",
  "month",
  "number",
  "url",
  "week",
  "search",
  "tel",
]

function Input(props) {
  const {
    id,
    label,
    name,
    defaultValue,
    type,
    readOnly,
    className,
    controlClassName,
    required,
    disabled,
    hint,
    placeholder,
    onChange,
    value,
  } = props

  const [showHint, setShowHint] = useState(false)

  const [inputInfo, setInputInfo] = useState(null)

  const handleChange = e => {
    if (onChange && typeof onChange === "function") {
      onChange(e.target)
    }

    setInputInfo(e.target.value)
  }

  useEffect(() => {
    setInputInfo(value)
  }, [value])

  const toggleHint = () => setShowHint(prev => !prev)

  const renderHint = () =>
    showHint ? (
      <div className="Form-hint">
        {hint}.{" "}
        <span
          tabIndex={0}
          role="button"
          onClick={toggleHint}
          onKeyDown={toggleHint}
          className="Form-hintClose"
        >
          [X] Cerrar
        </span>
      </div>
    ) : null

  const renderHintIcon = () =>
    hint ? (
      <span
        tabIndex={0}
        role="button"
        onClick={toggleHint}
        onKeyDown={toggleHint}
        className="Form-hintIcon"
      >
        {" "}
        [?]
      </span>
    ) : null

  return (
    <Fragment>
      {renderHint()}
      <div className="">
        <label className={`Form-label ${className}`} htmlFor={id}>
          {label}
          {renderHintIcon()}:
        </label>
        <input
          id={id}
          name={name}
          type={type}
          className={`Form-control ${controlClassName}`}
          readOnly={readOnly}
          defaultValue={defaultValue}
          required={required}
          disabled={disabled}
          onChange={handleChange}
          placeholder={placeholder}
          value={inputInfo}
        />
      </div>
    </Fragment>
  )
}

Input.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  type: PropTypes.oneOf(INPUT_TYPES),
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  controlClassName: PropTypes.string,
  hint: PropTypes.string,
  placeholder: PropTypes.string,
}

Input.defaultProps = {
  className: "",
  controlClassName: "",
  type: "text",
  placeholder: "",
}

export default Input
